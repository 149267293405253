import View from './View.js';
import { dataProgSkills } from './dataProgSkills.js';
import { dataSysSkills } from './dataSysSkills.js';
import { dataProjects } from './dataProjects.js';

export default class WorkView extends View {
	static school_element = `<p class="school-work">École</p>`;

	constructor(element) {
		super(element);
		this.init();
	}

	init() {
		const skills_element = this.element.querySelector('.work-skills');
		dataProgSkills.forEach(skill => {
			skills_element.innerHTML += this.getSkillHTML(skill);
		});
		dataSysSkills.forEach(skill => {
			skills_element.innerHTML += this.getSkillHTML(skill);
		});
		const projects_element = this.element.querySelector('.work-projects');
		dataProjects.forEach(project => {
			projects_element.innerHTML += this.getProjectHTML(project);
		});
		this.element
			.querySelectorAll('.work-projects>.work-project')
			.forEach(project => {
				project.addEventListener('click', () =>
					this.handleProjectPanel(project)
				);
			});
	}

	handleProjectPanel(project) {
		const lastActive = this.element.querySelector('.work-project-panel.active');
		const panel = project.querySelector('.work-project-panel');
		lastActive?.classList.remove('active');
		panel.classList.add('active');
		panel.querySelector('.back').addEventListener('click', event => {
			event.preventDefault();
			event.stopPropagation();
			panel.classList.remove('active');
		});
	}

	getSkillHTML(skill) {
		return `<div class="work-skill">
					<div class="skill-language">
						<img src="${skill.image}" alt="${skill.title.toLowerCase()}-logo"></img>
						<p>${skill.title}</p>
					</div>
					<p class="desc">${skill.description}</p>
                </div>`;
	}

	getProjectHTML(projet) {
		return `
        <div class="work-project">
            <img src="${projet.icon}" alt="_${projet.title}_"></img>
            <div class="work-project-intro">
                <p class="title">${projet.title}</p>
                <p>${projet.type}</p>
                <p class="desc">${projet.mini_desc}</p>
            </div>
            ${projet.school ? WorkView.school_element : ''}
            
            <div class="work-project-panel">
				<img src="${projet.images[0]}" alt="_${projet.title}_"></img>
				<div class="title">
					<a class="back">Retour</a>
					<p class="title">${projet.title}</p>
					${projet.school ? WorkView.school_element : ''}
				</div>
                <div class="desc">
					<div class="desc">${projet.skills.join(', ')}</div>
					<div class="desc">
                        <p>Date de debut: ${projet.start_date}</p>
                        <p>Date de fin: ${projet.end_date}</p>
                    </div>
					${projet.desc !== '' ? `<div class="desc">${projet.desc}</div>` : ''}
                </div>
				${
					projet.link != undefined && projet.link != ''
						? `<a href="${projet.link}" class="work-project-button" target="_blank">Aller au projet
					<img src="./images/logo/contact/export-white-logo.svg" alt="export-logo" class="export"></img>
					</a>`
						: ''
				}
			</div>
        </div>`;
	}
}
