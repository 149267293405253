import Router from './Router.js';
import View from './View.js';
import WorkView from './WorkView.js';
import AboutView from './AboutView.js';

const presentationView = new View(
	document.querySelector('.viewContent .presentation')
);
const workView = new WorkView(document.querySelector('.viewContent .work'));
const experienceView = new View(
	document.querySelector('.viewContent .experience')
);
const aboutView = new AboutView(
	document.querySelector('.viewContent .about'),
	document.querySelector('footer .contact-part')
);

Router.routes = [
	{ path: '/', view: presentationView, title: 'Presentation' },
	{ path: '/work', view: workView, title: 'Projets' },
	{ path: '/experience', view: experienceView, title: 'Experience' },
	{ path: '/about', view: aboutView, title: 'À propos' },
];

Router.init(document.querySelector('body'));
