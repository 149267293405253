export const dataSysSkills = [
	{
		title: 'IntelliJ Idea',
		description: `IDE qui est principalement utilisé pour le développement en Java, mais qui prend également en charge d'autres langages, notammend Kotlin, Groovy, Scala, JavaScript, TypeScript, etc.`,
		image: 'images/logo/sys-skills/intellij-idea-logo.svg',
	},
	{
		title: 'VS Code',
		description: `Logiciel open source, c'est un éditeur de code extensible développé par Microsoft qui peut-être utilisé avec une grande variété de langages de programmation.`,
		image: 'images/logo/sys-skills/vscode-logo.svg',
	},
	{
		title: 'Unity',
		description: `Moteur de jeu multiplateforme développé par Unity Technologies. Dispose d'une licence gratuite avec quelques limitations de technologie avancée au niveau de l'éditeur, mais sans limitation au niveau du moteur.`,
		image: 'images/logo/sys-skills/unity-logo.svg',
	},
	{
		title: 'Git',
		description: `Logiciel libre de gestion de versions crée en 2005, il est le plus important hébergeur de code informatique`,
		image: 'images/logo/sys-skills/git-logo.svg',
	},
	{
		title: 'Nginx',
		description: `Serveur web ainsi qu'un reverse proxy, il a pour particularité d'être un serveur asynchrone `,
		image: 'images/logo/sys-skills/nginx-logo.svg',
	},
	{
		title: 'PostgreSQL',
		description: `Système de base de données relationnelle, libre, open source et stable, il donne la priorité à la conformité et à l'extensibilité SQL`,
		image: 'images/logo/sys-skills/postgresql-logo.svg',
	},
	{
		title: 'MySQL',
		description: `Système de base de données relationnelle, libre, open source et stable, il donne la priorité à l'évolutivité et aux performances`,
		image: 'images/logo/sys-skills/mysql-logo.svg',
	},
	{
		title: 'Node.js',
		description: `Developpé en JavaScript, il est orientée vers les applications réseau évènementielles ce qui permet le développement de serveur HTTP sans avoir à installer ou utiliser des serveurs webs`,
		image: 'images/logo/sys-skills/nodejs-logo.svg',
	},
	{
		title: 'Babel.js',
		description: `Transcompilateur JavaScript open source qui est principalement utilisé pour convertir le code EMACScript 2015+ en code rétrocompatible pour d'anciens moteurs`,
		image: 'images/logo/sys-skills/babeljs-logo.svg',
	},
	{
		title: 'Bruno',
		description: `Client d'API open source qui permet de faire des appels REST ou GraphQL à vos API et offre la possibilité d'en automatiser les tests`,
		image: 'images/logo/sys-skills/bruno-logo.webp',
	},
	{
		title: 'Docker',
		description: ``,
		image: 'images/logo/sys-skills/docker-logo.svg',
	},
	{
		title: 'Android Studio',
		description: `Environnement de développement basé sur IntelliJ IDEA pour développer des applications mobiles Android`,
		image: 'images/logo/sys-skills/android-studio-logo.svg',
	},
	{
		title: 'Java EE',
		description: ``,
		image: 'images/logo/sys-skills/java-ee-logo.webp',
	},
];
