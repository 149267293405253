import View from './View.js';

export default class AboutView extends View {
	constructor(element, contactFooter) {
		super(element);
		this.contactFooter = contactFooter;
	}

	show() {
		super.show();
		this.contactFooter.classList.add('hide');
	}
	hide() {
		super.hide();
		this.contactFooter.classList.remove('hide');
	}
}
