export default class Router {
	static routes;

	static lastView;

	static navigate(path) {
		this.#navigateTo(path);
		window.history.pushState(null, null, path);
	}

	static #navigateTo(path) {
		console.log(`Navigate to ${path}`);
		if (this.lastView != null) {
			this.lastView.hide();
		}
		const view = this.routes.find(route => route.path == path).view;
		view.show();
		this.lastView = view;
	}

	static init(menuElement) {
		this.navigate(this.currentLocation());
		window.onpopstate = () => {
			this.#navigateTo(this.currentLocation());
		};

		const links = menuElement.querySelectorAll('.link');
		links.forEach(link =>
			link.addEventListener('click', event => {
				event.preventDefault();
				this.navigate(event.currentTarget.getAttribute('href'));
			})
		);
	}

	static currentLocation() {
		const parts = document.location.toString().split('/');
		return `/${parts[parts.length - 1]}`;
	}
}
