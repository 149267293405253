export const dataProgSkills = [
	{
		title: 'TypeScript',
		description: `Développé par Microsoft, ce langage est un sur-ensemble de JavaScript qui a pour but d'améliorer et sécuriser le code.`,
		image: 'images/logo/prog-skills/typescript-logo.svg',
	},
	{
		title: 'JavaScript',
		description: `Le JavaScript est un langage de programmation de scripts principalement employé dans les pages web interactives, il est une partie essentielle des applications web.`,
		image: 'images/logo/prog-skills/javascript-logo.svg',
	},
	{
		title: 'C#',
		description: `Le C# est un langage de programmation orientée objet, dérivé de C et de C++, ressemblant au langage Java.`,
		image: 'images/logo/prog-skills/csharp-logo.svg',
	},
	{
		title: 'Java',
		description: `Le Java est un language de programmation orientée objet, il reprend en grande partie la syntaxe du C++ en simplifiant la gestion des notions, des pointeurs, etc.`,
		image: 'images/logo/prog-skills/java-logo.svg',
	},
	{
		title: 'CSS',
		description: `CSS ou Cascading Style Sheets, est un langage informatique qui décrit la présentation des documents HTML.`,
		image: 'images/logo/prog-skills/css-logo.svg',
	},
	{
		title: 'HTML',
		description: `HTML ou HyperText Markup Language, est utilisé afin de créer et de représenter le contenu d'une page web et sa structure.`,
		image: 'images/logo/prog-skills/html-logo.svg',
	},
	{
		title: 'SQL',
		description: `Le SQL (Structured Query Language) permet de manipuler les données et les systèmes de bases de données relationnelles.`,
		image: 'images/logo/prog-skills/sql-logo.svg',
	},
	{
		title: 'C++',
		description: `C++ est un langage de programmation compilé, permettant la programmation sous de multiple forme, dont la programmation procédurale, la programmation orientée objet et la programmation générique. C'est un des langages de programmation les plus utilisés dans les applications où la performance est critique.`,
		image: 'images/logo/prog-skills/cpp-logo.svg',
	},
	{
		title: 'C',
		description: `Le C est un langage de programmation impératif, généraliste et de bas niveau. Inventé en 1972, il est devenu un des langages les plus utilisés.`,
		image: 'images/logo/prog-skills/c-logo.svg',
	},
	{
		title: 'Bash',
		description: `Bash ou Bourne-Again Shell, est un interpréteur en ligne de commande de type script.`,
		image: 'images/logo/prog-skills/bash-logo.svg',
	},
];
